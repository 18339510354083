'use client'

import { useEffect, useState } from "react"
import { RollbarPlugin, useEventListener } from "@ginieapp/ginie-ui"

export default function LayoutPlugins(props) {

  const [loaded,setLoaded] = useState(false)

  const gaId = "G-3QVKNYBKRR"

  useEventListener("log_event_ext", event => {
    if (loaded) {
      let data = event.detail
      let key = data.en + "_" + data.et + (data.c ? "_" + data.c : "")
      logGAEvent(key, data)
    }
  })

  useEffect(() => {
    load(gaId);
  }, [])

  const load = (dtmId) => {
    dtmId = dtmId || gaId
    if (!loaded && dtmId) {
      const script = document.createElement('script');
      script.src = "https://www.googletagmanager.com/gtag/js?id=" + dtmId;
      script.async = true;
      script.onload = initGTM(dtmId);
      document.body.appendChild(script);
      setLoaded(true)
    }
  }

  const initGTM = (dtmId) => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', dtmId,{ 'debug_mode':true });
  }
  
  return <>
    {/* <RollbarPlugin accessToken={process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN} env={process.env.NEXT_PUBLIC_ENV} enabled={true} codeVersion={process.env.NEXT_PUBLIC_VERSION}></RollbarPlugin> */}
  </>

}

export function logGAEvent(eventName, params) {
  try {
    gtag('event', eventName, params)
  } catch (e) {
    // ignore
  }
}
